import Logo from 'src/Presentation/components/logo';
import { Box } from '@mui/material';
import AutocompleteSectorsAndIndustries from './AutocompleteSectorsAndIndustries';
import SearchTerms from './SearchTerms';
import AccountPopover from '../AccountPopover';
import SelectorSectorsAndIndustries from './SelectorSectorsAndIndustries';

export default function HeaderMainPageDesktop() {
  return (
    <Box
      gap={2}
      display="grid"
      gridTemplateColumns={{ md: '1fr 2fr 2fr 2fr' }}
      sx={{ height: 80, ml: 2, mt: 2, mb: 8 }}
    >
      <Logo />
      <SelectorSectorsAndIndustries />

      <SearchTerms />

      <AccountPopover />
    </Box>
  );
}
