import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import SessionModal from 'src/Presentation/pages/mainPage/components/SessionModal';
import { useContext, useState } from 'react';
import { SearchContext } from 'src/Presentation/context/SearchContext';
import { SessionModalContext } from 'src/Presentation/context/SessionModalContext';
import HeaderMainPage from './header/headerMainPage/HeaderMainPage';
import HeaderPage from './header/headerPage/HeaderPage';

interface DashboardLayoutProps {
  layout: string;
  title: string;
}

export default function DashboardLayout(props: DashboardLayoutProps) {
  const { layout, title } = props;
  const { setSearch } = useContext(SearchContext);
  const { showModal, setShowModal } = useContext(SessionModalContext);
  const [openSessionModal, setOpenSessionModal] = useState<boolean>(true);

  const handleCloseModal = () => {
    setOpenSessionModal(false);
    setShowModal(false);
  };

  const handleContinueSession = () => {
    setOpenSessionModal(false);
    setShowModal(false);
  };

  const handleStartNewSession = () => {
    setSearch((prevState) => ({
      ...prevState,
      industry: {
        ...prevState.industry,
        openOptions: true,
        modalOpened: true,
        terms: [],
        value: '',
        label: '',
        sector_ref: '',
      },
      activeTab: '',
      subTabActive: '',
      terms: [],
    }));
    setOpenSessionModal(false);
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <SessionModal
          open={openSessionModal}
          onClose={handleCloseModal}
          onContinue={handleContinueSession}
          onStartNew={handleStartNewSession}
        />
      )}
      {!showModal && (
        <Box sx={{ overflowY: 'hidden' }}>
          {layout === 'articles' ? <HeaderMainPage /> : <HeaderPage title={title} />}
          <Box>
            <Outlet />
          </Box>
        </Box>
      )}
    </>
  );
}
