import { Box, Grid, Typography } from '@mui/material';
import Logo from 'src/Presentation/components/logo';

import AlertNotification from 'src/Presentation/pages/components/AlertNotification';
import AccountPopover from '../AccountPopover';

interface HeaderPageProps {
  title: string;
}

export default function HeaderPage(props: HeaderPageProps) {
  const { title } = props;
  return (
    <>
      <Box sx={{ marginTop: 2, marginX: 2, mb: 5 }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={3}>
            <Logo />
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h3" color="primary">
              {title}
            </Typography>
          </Grid>

          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AccountPopover />
          </Grid>
        </Grid>
      </Box>
      <AlertNotification />
    </>
  );
}
