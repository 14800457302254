import { Suspense, lazy, ElementType } from 'react';

import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const AccountActivation = Loadable(lazy(() => import('../pages/auth/AccountActivation')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

export const FirstEntryPage = Loadable(
  lazy(() => import('../pages/firstEntryPage/FirstEntryPage'))
);
export const MainPage = Loadable(lazy(() => import('../pages/mainPage/MainPage')));
export const SavedSearches = Loadable(lazy(() => import('../pages/savedSearches/SavedSearches')));
export const Emails = Loadable(lazy(() => import('../pages/emails/Emails')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
